/* eslint-disable @typescript-eslint/no-use-before-define */

export function formatCurrency(value: number | string): string {
  if (!isNumber(value)) return '';

  const fixed = (
    Math.round((Number(value) + Number.EPSILON) * 100) / 100
  ).toFixed(2);
  const [whole, decimal] = fixed.split('.');

  return `${Number(whole).toLocaleString()}.${decimal}`;
}

function isNumber(value: string | number | undefined | null) {
  const strValue = String(value ?? '').trim();
  const numValue = +strValue;
  const isNum = strValue !== '' && !Number.isNaN(numValue);
  return isNum;
}
