/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { createTheme } from '@abyss/web/tools/theme';
import { createRouter } from '@abyss/web/tools/createRouter';
import { AbyssProvider } from '@abyss/web/ui/AbyssProvider';
import { useHasRole } from '@src/hooks/useHasRole';
import { PageBody } from '@abyss/web/ui/PageBody';
import { UnauthorizedErrorPage } from '@src/common/Error';
import { LoadingPage } from '@src/routes/provider/ffc/manage/LoadingPage';
import { useCountdown } from '@abyss/web/hooks/useCountdown';
import { useGroupSearch } from '@src/hooks/useGroupsSearch';
import { InvalidTokenErrorPage } from '@src/common/InvalidTokenError';
import { Routes } from './routes';
import './assets/css/styles.css';

const theme = createTheme('uhg');
const router = createRouter(Routes);

export const browser = () => {
  const [isLoading, setLoading] = useState(true);
  const onCompleted = () => setLoading(false);
  const { formattedTime } = useCountdown({ time: 2 * 1_000, onCompleted });

  const [isTokenValid, setIsTokenValid] = useState(true);
  const [, setIsLoggedIn] = useState(true);
  const [hasLoginRole, setHasLoginRole] = useState(true);

  // const [, justPingMotherShip] = useHasRole({
  //   onCompleted: (_response) => {
  //     console.log(`The Mother-ship alive...`);
  //   },
  //   onError: (_err) => {
  //     console.log(`The Mother-ship is down...`);
  //   },
  // });

  const [, userHasLoginRole] = useHasRole({
    onCompleted: (response) => {
      setHasLoginRole(Object.freeze(response?.data?.hasRole)?.login || false);
    },
    onError: () => {
      setHasLoginRole(false);
      setLoading(false);
    },
  });

  const [, getSecureGroups] = useGroupSearch({
    onCompleted: () => {
      // ok, now we should have the user's groups assigned so we can now query for their roles/permissions
      setIsTokenValid(true); // response.data.groups?.tokenValidated);
      // setIsLoggedIn(response.data.groups?.loggedIn);
      userHasLoginRole({
        variables: {
          roles: ['login'],
        },
      });
    },
    onError: (err) => {
      // setIsLoggedIn(false);
      const status = err.response?.status;
      if (status === '401') setIsLoggedIn(false);
      if (status === '403') setIsTokenValid(false);
      console.error('Failed to get secure groups:= for user:', err);
    },
  });

  useEffect(() => {
    // we need to get the groups for this user so we can then get the associated roles/permissions/authorizations/authorities
    getSecureGroups();
  }, [formattedTime]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     justPingMotherShip( {
  //       variables: {
  //         roles: ['expect-this-to-return-false-as-it-does-not-exist']
  //       }
  //     });
  //   }, 15 * 60 * 1_000); // Adjust the interval time as needed (5000ms = 5 seconds)
  //
  //   return () => clearInterval(interval); // Cleanup the interval on component unmount
  // }, []);

  const renderNoAccess = () => {
    return (
      <React.Fragment>
        <PageBody>
          <br />
          <br />
          <br />
          <UnauthorizedErrorPage />
          <br />
        </PageBody>
      </React.Fragment>
    );
  };

  const renderWithAccess = () => {
    return <AbyssProvider theme={theme} router={router} />;
  };

  const renderLoading = () => {
    return (
      <LoadingPage
        isLoading={isLoading}
        message="Please hang on in there while we load up your permissions..."
        title="Loading Your Permissions"
      />
    );
  };

  const renderWithInvalidToken = () => {
    return <InvalidTokenErrorPage />;
  };

  return (
    <div>
      {isLoading && renderLoading()}
      {/* { !isLoading && (hasLoginRole ? (renderWithAccess()) : (renderNoAccess())) } */}
      {!isLoading &&
        (isTokenValid
          ? hasLoginRole
            ? renderWithAccess()
            : renderNoAccess()
          : renderWithInvalidToken())}
    </div>
  );
};
